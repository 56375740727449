import React from 'react'
import nswLogo from './assets/images/header/logo-nsw.png'
import snswLogo from './assets/images/header/logo-snsw.png'

export function Header() {
  return (
    <div>
      <div className='bg-white py-2 pr-1 m-2'>
        <div className='flex'>
          <img className='h-12 px-1 ml-32' src={nswLogo} alt='NSW logo' />
          <div className='h-12 border-l border-gray-300 mx-2' />
          <img className='h-12 px-1' src={snswLogo} alt='Service NSW logo' />
        </div>
      </div>
      <div className='flex items-center bg-snsw-gray-3 h-24 shadow'>
        <div className='flex-1 text-4xl font-bold ml-32'>CPP Drives Dashboard</div>
      </div>
    </div>

  )
}
