import ReconnectingEventSource from 'reconnecting-eventsource'

export const EventSourceFactory = {
  create(url) {
    return new ReconnectingEventSource(url, {
      withCredentials: false,
      max_retry_time: 1000,
    })
  },
}

export default EventSourceFactory
