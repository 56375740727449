import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Header} from './Header'
import {Footer} from './Footer'
import {CsrIdInputForm} from './CsrIdInputForm'
import CsrPaymentDetails from './CsrPaymentDetails'
import PaymentsStreaming from './PaymentsStreaming'
import PropTypes from 'prop-types'
import {ErrorPage} from './ErrorPage'
const TOO_MANY_REQUESTS=429

const fetchCsrIdForCompletedPayment = (completionReference) => axios
  .get(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter?completionReference=${completionReference}`)
  .then(response => response.data.drivesCsrId)

const reloadPageToViewAllCsrPayments = (drivesCsrId) => {
  window.location.href = `${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL}?csrId=${drivesCsrId}`
}

const cancelPaymentReturningCsrIdForPayment = (paymentReference) => axios
  .post(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments/${paymentReference}/cancel`)
  .then(response => response.data.drivesCsrId)

const voidPaymentReturningCsrIdForPayment = (paymentReference) => axios
  .post(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments/${paymentReference}/void`)
  .then(response => response.data.drivesCsrId)

const updatePaymentReturningCsrIdForPayment = (paymentReference) => axios
  .put(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments/${paymentReference}`)
  .then(response => response.data.drivesCsrId)

const App = ({completionReference, csrId, paymentReference, action}) => {
  const [paymentDetailsMap, setPaymentDetailsMap] = useState(new Map())
  const [rateLimitExceeded, setRateLimitExceeded] = useState(false)

  useEffect(() => {
    axios.interceptors.response.use(
      function(successRes) {
        return successRes
      },
      function(e) {
        if (e.response && e.response.status === TOO_MANY_REQUESTS){
          setRateLimitExceeded(true)
        }
        console.error('axios error:', e.message)
        return Promise.reject(e)
      }
    )

    if (completionReference) {
      fetchCsrIdForCompletedPayment(completionReference)
        .then(reloadPageToViewAllCsrPayments)
    } else if (csrId) {
      PaymentsStreaming.streamForPaymentUpdates(csrId, setPaymentDetailsMap)
    } else if (paymentReference && action === 'cancel') {
      cancelPaymentReturningCsrIdForPayment(paymentReference)
        .then(reloadPageToViewAllCsrPayments)
        .catch(e => console.error('failed to cancel payment:', e.message))
    } else if (paymentReference && action === 'void') {
      voidPaymentReturningCsrIdForPayment(paymentReference)
        .then(reloadPageToViewAllCsrPayments)
    } else if (paymentReference && isContactCentrePayment(paymentReference) ) {
      updatePaymentReturningCsrIdForPayment(paymentReference)
        .then(reloadPageToViewAllCsrPayments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {rateLimitExceeded && <ErrorPage/>}
      {!rateLimitExceeded &&
      <div className='App flex flex-col space-y-3 min-h-screen justify-between'>
        <Header/>
        <p>{rateLimitExceeded}</p>
        <CsrIdInputForm onSubmit={reloadPageToViewAllCsrPayments}/>
        {csrId && <CsrPaymentDetails paymentDetails={paymentDetailsMap} csrId={csrId} onVoidError={
          () => setRateLimitExceeded(true)
        }/>}
        <Footer/>
      </div>}
    </div>
  )
}

App.propTypes = {
  completionReference: PropTypes.string,
  csrId: PropTypes.string,
  paymentReference: PropTypes.string,
  action: PropTypes.string,
}

export default App

export const isContactCentrePayment = (paymentReference) => paymentReference.startsWith('CPP-CC')
