import React, { useState } from 'react'
import PropTypes from 'prop-types'

export function CsrIdInputForm({onSubmit}) {
  const [csrIdInputValue, setCsrIdInputValue] = useState('')
  const handleChangeCsrIdInput = (e) => setCsrIdInputValue(e.target.value)
  const handleCsrIdButtonClick = () => onSubmit(csrIdInputValue)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && csrIdInputValue) {
      onSubmit(csrIdInputValue)
    }
  }
  return (
    <div className='mt-8 w-2/3 self-center'>
      <label className='self-center text-lg'>
                CSR ID
        <input
                    id="csr-id-input"
                    value={csrIdInputValue}
                    autoFocus
                    onChange={handleChangeCsrIdInput}
                    onKeyPress={handleKeyPress}
                    className="m-8 csr-id-class text-right p-2 border-2 border-snsw-gray-4 rounded text-sm"
        />
      </label>
      <button
                id="csr-id-button"
                className='mx-4 my-6 rounded-md bg-snsw-gray-2 p-2 border-2 border-snsw-gray-5 font-bold text-snsw-blue-2'
                onClick={handleCsrIdButtonClick}
      >
                Submit
      </button>
    </div>
  )
}
CsrIdInputForm.propTypes = {
  onSubmit: PropTypes.func,
}
