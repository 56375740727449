import React from 'react'

export function Footer() {
  return (
    <div>
      <div className='bg-white p-8 border-t border-snsw-gray-5'>
        <div className='text-center'>
          <div className='ml-6 text-sm text-snsw-gray-10 self-center'>We pay respect to the Traditional Custodians and First Peoples of NSW, and acknowledge their
                        continued connection to their country and culture.
          </div>
        </div>
      </div>
      <div className='bg-snsw-gray-9 h-20'/>
    </div>
  )
}
