import axios from 'axios'
import EventSourceFactory from './EventSourceFactory'
import {isContactCentrePayment} from './App'

const fetchAllPaymentsForCSR = (drivesCsrId) => axios
  .get(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments?csrId=${drivesCsrId}`)
  .then(response => response.data)
  .catch(e => console.error('could not retrieve payments:', e.message))

const redirectToCPPFrontEndToTakePayment = (cppPaymentReference) => {
  const targetFrontend = isContactCentrePayment(cppPaymentReference) ?
    process.env.REACT_APP_CPP_CC_FRONTEND_URL : process.env.REACT_APP_CPP_OTC_FRONTEND_URL
  window.location.href = `${targetFrontend}/?paymentReference=${cppPaymentReference}`
  console.log(window.location.href)
}

export const PaymentsStreaming = {
  streamForPaymentUpdates(csrId, setPaymentDetailsMapCallback) {
    console.log('** streamForPaymentUpdates : ' + csrId + ' at date: ' + Date())
    fetchAllPaymentsForCSR(csrId)
      .then(paymentsList => {
        PaymentsStreaming.redirectToCPPOnPendingOrUnpayPayment(csrId, paymentsList)
          .then(resultantPaymentsList => {
            const latestPaymentUpdates = {}
            resultantPaymentsList.forEach(payment => {
              if (!latestPaymentUpdates[payment.drivesTransactionId]) {
                latestPaymentUpdates[payment.drivesTransactionId] = payment
              }
            })
            setPaymentDetailsMapCallback({...latestPaymentUpdates})
            PaymentsStreaming.startEventSourceForPayment(csrId, latestPaymentUpdates, setPaymentDetailsMapCallback)
          })
      }).catch(e => console.error('error while retrieving streaming payments:', e.message))
  },

  redirectToCPPOnPendingOrUnpayPayment(csrId, paymentsList) {
    const needsPayOrUnpay = paymentsList.length > 0
      && (paymentsList[0].status === 'PENDING' || paymentsList[0].status === 'PAID'
        || (paymentsList[0].status === 'UNPAY_REQUESTED'
          && paymentsList[0].unpayCsrId.toLowerCase() === csrId.toLowerCase()))
    return new Promise((resolve) => {
      if (needsPayOrUnpay) {
        redirectToCPPFrontEndToTakePayment(paymentsList[0].cppPaymentReference)
      } else {
        resolve(paymentsList)
      }
    })
  },

  addAtTheBeginning(paymentsMap, latestPayment) {
    const trxIdToBeUpdated = latestPayment.drivesTransactionId
    delete paymentsMap[trxIdToBeUpdated]
    const paymentsArr = Object.values(paymentsMap)
    paymentsArr.unshift(latestPayment)
    return paymentsArr.reduce(function (map, payment) {
      map[payment.drivesTransactionId] = payment
      return map
    }, {})
  },

  startEventSourceForPayment(csrId, paymentsMap, setPaymentDetailsMapCallback) {
    const eventSource = EventSourceFactory.create(`${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments-flux/${csrId}`)
    let paymentsMapCurrent = paymentsMap
    eventSource.onmessage = e => {
      const fluxEvent = JSON.parse(e.data)
      if (fluxEvent.status === 'HEARTBEAT') {
        if (process.env.REACT_APP_ENV === 'local') {
          console.log('app event source heartbeat occurred: ', JSON.stringify(e.data))
        }
        return
      }

      console.log('app event source onmessage occurred: ', JSON.stringify(e.data))
      const newPayment = fluxEvent
      PaymentsStreaming.redirectToCPPOnPendingOrUnpayPayment(csrId, [newPayment])
        .then(latestPayments => {
          const updatedPaymentMap = PaymentsStreaming.addAtTheBeginning(paymentsMapCurrent, latestPayments[0])
          setPaymentDetailsMapCallback({...updatedPaymentMap})
          paymentsMapCurrent = updatedPaymentMap
        })
      if (newPayment.status && (newPayment.status === 'PENDING' || newPayment.status === 'UNPAY_REQUESTED')) {
        eventSource.close()
      }
    }
    eventSource.onerror = (e) => {
      console.log('app event source error occurred: ', JSON.stringify(e))
    }
  },
}

export default PaymentsStreaming
