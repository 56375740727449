import React from 'react'
import Modal from 'react-modal'
import * as PropTypes from 'prop-types'
import warningIcon from './assets/images/icons/warning.svg'
import './styles.css'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
    paddingBottom: '1em',
    paddingTop: '1em',
  },
  overlay: { background: 'rgba(0,0,0,0.5)' },
}

const knowledgeArticlePath = 'https://intranet.service.nsw.gov.au/node/7379/attachment'

export const RemovePaymentModal = ({
  showModal,
  closeModal,
  approve,
}) => {
  return (
    <div>
      <Modal
                isOpen={showModal}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={closeModal}
      >
        <div className="flex-1 text-2xl font-bold mt-5 mb-6">Approve payment removal</div>
        <div>
          <div className="error-panel mx-2 max-w-xl">
            <div className="flex-auto mt-2 mb-4 mx-2">
              <img className="icon" src={warningIcon} alt="Warning icon"/>
            </div>
            <div className="flex-auto mt-2 mb-4 mx-2">
              <h5>Warning - unpay should be performed in DRIVES</h5><br/>
              <p>ONLY use this option if you are sure override in CPP is necessary.</p><br/>
              <p>For details, please refer to this&nbsp;
                <a className="error-link" href={knowledgeArticlePath} target="_blank" rel="noopener noreferrer">knowledge article.</a></p><br/>
              <h5>Note</h5>
              <ul className="error-note">
                <li>Using this option results in additional administrative work</li>
                <li>Initiate UnPay in DRIVES to refund the customer</li>
                <li>Remove payment in CPP will not change the status of the invoice in DRIVES</li>
              </ul>
            </div>
          </div>

          <div className="mt-6 mb-2 mx-2">
            <h5>Do you wish to proceed?</h5>
          </div>

          <div className="mt-2 mb-2 mx-2 flex content-center justify-center">
            <div>
              <button
                id="modal-close-button"
                onClick={closeModal}
                data-testid="no-button"
                className="mx-12 mt-6 mb-4 rounded-md bg-snsw-gray-2 px-16 py-2 border-2 border-snsw-gray-5 font-bold text-snsw-blue-2 content-around">
                No
              </button>
            </div>
            <div>
              <button
                id="modal-approve-button"
                data-testid="yes-button"
                onClick={approve}
                className={'mx-12 mt-6 mb-4 bg-primary rounded-md px-16 py-2 font-bold text-white'}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

RemovePaymentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
}
