import React from 'react'
import ReactDOM from 'react-dom'
import './output.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import queryString from 'query-string'

const completionReferenceParam = queryString.parse(window.location.search).completionReference
const csrId = queryString.parse(window.location.search).csrId
const paymentReference = queryString.parse(window.location.search).paymentReference
const action = queryString.parse(window.location.search).action

ReactDOM.render(
  <React.StrictMode>
    <App
        completionReference={completionReferenceParam}
        csrId={csrId}
        paymentReference={paymentReference}
        action={action}
    />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
