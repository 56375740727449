import React, {Fragment, useState} from 'react'
import './CsrPaymentDetails.css'
import axios from 'axios'
import PropTypes from 'prop-types'
import {RemovePaymentModal} from './RemovePaymentModal'
import {isContactCentrePayment} from './App'

const TOO_MANY_REQUESTS=429

function checkIfPaymentIsEligibleForUnPay(paymentDetailsList, paymentDetail) {
  return paymentDetailsList && paymentDetailsList.length > 0
    && paymentDetailsList[0].drivesTransactionId
    && paymentDetailsList[0].drivesTransactionId === paymentDetail.drivesTransactionId
    && paymentDetailsList[0].cppPaymentReference
    && paymentDetailsList[0].cppPaymentReference === paymentDetail.cppPaymentReference
    && !isContactCentrePayment(paymentDetail.cppPaymentReference)
    && paymentDetail.status === 'COMPLETED'
    && process.env.REACT_APP_UNPAY_ENABLED === 'true'
}

function reverseAndRedirectToOTC(paymentDetail, onVoidError) {
  const postPath = `${process.env.REACT_APP_CPP_CSR_ADAPTER_BFF_BASE_URL || ''}/api/drives/adapter/payments/${paymentDetail.cppPaymentReference}/unpayFromDashboardUI`
  axios.post(postPath)
    .then(
      () => window.location.href =
        `${process.env.REACT_APP_CPP_OTC_FRONTEND_URL}/?paymentReference=${paymentDetail.cppPaymentReference}`)
    .catch(e => {
      console.error('Could not \'remove payment\':', e.message)
      if(e.response && e.response.status===TOO_MANY_REQUESTS) {
        onVoidError()
      }
    })
}

const CsrPaymentDetails = ({paymentDetails, csrId, onVoidError}) => {
  const [removePaymentModalVisible, setRemovePaymentModalVisible] = useState(false)

  const sortedPaymentDetails = paymentDetails ? Object.values(paymentDetails).sort((l, r) => {
    return new Date(r.requestedTimestamp) - new Date(l.requestedTimestamp)
  }) : []
  return (
    <div className="mt-8 w-2/3 h-full self-center flex-grow">
      <div className="csrId">CSR ID: {csrId}</div>
      <table>
        <thead>
          <tr>
            <th className="p-3">DRIVES Transaction ID</th>
            <th className="p-3">Amount</th>
            <th className="p-3">Location ID / IP Address</th>
            <th className="p-3">CPP Payment Reference</th>
            <th className="p-3">Status</th>
            <th className="p-3">Requested Timestamp</th>
            <th className="p-3">Receipt</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {paymentDetails &&
        sortedPaymentDetails
          .map((paymentDetail) => (
            <tr key={paymentDetail.drivesTransactionId}>
              <td className="p-3">{paymentDetail.drivesTransactionId}</td>
              <td className="p-3">{paymentDetail.amount}</td>
              <td className="p-3">{paymentDetail.locationId}</td>
              <td className="p-3">{paymentDetail.cppPaymentReference}</td>
              <td className="p-3">{paymentDetail.status}</td>
              <td className="p-3">{paymentDetail.requestedTimestamp}</td>
              <td className="p-3">
                {['COMPLETED', 'UNPAY_REQUESTED', 'UNPAID'].includes(paymentDetail.status)
                    &&
                    <a
                        href={encodeURI(`${process.env.REACT_APP_PSP_LEGACY_RECEIPT_MANAGER_URL}/preview/DRIVES/${paymentDetail.drivesTransactionId}?csrId=${csrId}`)}
                        target="_blank"
                        className="underline text-blue-600 hover:text-blue-800"
                        rel="noopener noreferrer"
                    >Open Receipt</a>
                }
              </td>
              <td className="p-3">
                {
                  checkIfPaymentIsEligibleForUnPay(sortedPaymentDetails, paymentDetail) &&
                      <Fragment>
                        <button
                            id="unpay-button"
                            className='mx-4 my-6 rounded-md bg-snsw-gray-2 p-2 border-2 border-snsw-gray-5 font-bold text-snsw-blue-2'
                            onClick={() => setRemovePaymentModalVisible(true)}
                        >Remove Payment</button>
                        <RemovePaymentModal
                            approve={() => reverseAndRedirectToOTC(paymentDetail, onVoidError)}
                            closeModal={() => setRemovePaymentModalVisible(false)}
                            showModal={removePaymentModalVisible}/>
                      </Fragment>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>)
}

CsrPaymentDetails.propTypes = {
  csrId: PropTypes.string,
  paymentDetails: PropTypes.object,
  onVoidError: PropTypes.func,
}

export default CsrPaymentDetails
